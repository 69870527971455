import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import Img from "../../../img/temp/Screenshot 2022-12-30 110714.png";

export const Dropdown = ({
  option_arr,
  current_value,
  setcurrent_value,
  error,
  readonly,
}) => {
  // console.log("option_arr dropdown", option_arr, current_value);
  const [is_active, setis_active] = useState(false);
  const [to_top, setto_top] = useState(false);
  // useEffect(() => {}, []);
  const dropdown_main = useRef();
  const selection_group = useRef();
  useEffect(() => {
    setto_top(
      selection_group.current.getBoundingClientRect().bottom >
        window.innerHeight
    );
  }, []);
  return (
    <>
      <div
        ref={dropdown_main}
        className={"cust_dropdown" + (is_active ? " active" : "")}
        error={error ? error.toString() : "false"}
        onClick={() => setis_active(!is_active)}
        onMouseLeave={() => setis_active(false)}
        read_only={(readonly || false).toString()}
      >
        <div className="dropdown_txt">
          {option_arr.filter((el) => current_value === el.id)[0]?.value}
        </div>
        <div
          className={"selection_group" + (to_top ? " active" : "")}
          ref={selection_group}
        >
          <div>
            {option_arr.map((el, i) => (
              <div
                key={i}
                className={"item" + (current_value === el.id ? " active" : "")}
                onClick={() => setcurrent_value(el.id)}
              >
                {el.value}
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};
