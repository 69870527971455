import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Dropdown } from "./Custom/Dropdown/Dropdown";

export const CompanyYearSelector = ({ table_filters, settable_filters }) => {
  const year_start = 2022;
  const current_store = useSelector((state) => state);
  const [year_arr, setyear_arr] = useState([]);
  useEffect(() => {
    let temp_year_arr = [];
    for (let index = year_start; index <= new Date().getFullYear(); index++) {
      temp_year_arr.push({
        id: index,
        value: `${index} - ${index + 1}`,
      });
    }
    setyear_arr([...temp_year_arr]);
  }, []);
  // console.log(year_arr, current_store);
  return (
    <div className="companyyearselector">
      <div className="choosecompany">
        Choose Company for Financial year {table_filters.year}
      </div>
      <Dropdown
        option_arr={[...current_store.user.companies]}
        current_value={table_filters.company}
        setcurrent_value={(data) => {
          settable_filters((prev) => ({ ...prev, company: data }));
        }}
      />
      <Dropdown
        option_arr={[...year_arr]}
        current_value={table_filters.year}
        setcurrent_value={(data) => {
          settable_filters((prev) => ({ ...prev, year: data }));
        }}
      />
    </div>
  );
};
