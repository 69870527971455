import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import inverted_commas_left from "../img/inverted_commas_left.png";
import axios from "axios";
// import { Toast } from "./Toast";
import error from "../img/icons/error.svg";
import { Toast } from "../Component/Toast";
import { check_login, check_token } from "../Api/api";

export const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [toast_msg, settoast_msg] = useState("");
  const [login_data, setlogin_data] = useState({
    email: "",
    password: "",
    // email: "admin@app.com",
    // password: "adminpassword",
  });
  const [login_err, setlogin_err] = useState(false);

  const current_store = useSelector((store) => store);
  const [login_btn, setlogin_btn] = useState(false);
  useEffect(() => {
    if (current_store.user.token == "") {
      let local_token = localStorage.getItem("mtpl_data");
      if (!local_token) {
        // dispatch({ type: "logout" });
        // navigate("/");
        return;
      } else {
        local_token = JSON.parse(localStorage.getItem("mtpl_data"));
        check_token(
          {
            token: local_token,
          },
          (data) => {
            // console.log(data.data);
            const payload_data = {
              name: data.data.name,
              is_admin: data.data.type ? data.data.type == "admin" : false,
              section: data.data.section.map((el) => ({
                id: el._id,
                value: el.name,
              })),
              branch: data.data.branch.map((el) => ({
                id: el._id,
                value: el.name,
              })),
              token: local_token,
              companies: data.data.companies.map((el) => ({
                id: el._id,
                value: el.name,
              })),
            };

            // setpage_data((prev) => ({
            //   ...prev,
            //   branches: [
            //     { id: 0, value: "All Branches" },
            //     ...data.data.branch.map((el) => ({
            //       id: el._id,
            //       value: el.name,
            //     })),
            //   ],
            // }));
            dispatch({ type: "login", payload: payload_data });
            return navigate("/account");
            // if(data.data.type == "admin"){
            // }
          },
          () => {
            // dispatch({ type: "logout" });
            // return navigate("/");
          }
        );
        return;
      }
    }
    // }
  }, []);

  const login = async () => {
    // let login_body = {
    //   email: document.getElementById("username").value.trim(),
    //   password: btoa(document.getElementById("password").value.trim()),
    // };

    if (login_data.email != "" && login_data.password != "") {
      check_login({ ...login_data }, (data) => {
        console.log("login_api", data);
        if (data.err) {
          //   if (data.err_msg == "email or password incorrect") {
          setlogin_err(true);
          //     document.getElementById("username").parentNode.className =
          //       "input error";
          //     document.getElementById(`username_err`).innerHTML =
          //       "Please check for typo or email format as name@email.com";
          // //   } else {
          //     document.getElementById("password").parentNode.className =
          //       "input error";
          //     document.getElementById(`password_err`).innerHTML =
          //       "Password Incorrect. Please check for typo.";
          //     console.error("login err2", data.err_msg);
          //     return;
          //   }
          console.error("login err1", data.err_msg);
          return;
        } else {
          settoast_msg(data.message);
          const payload_data = {
            name: data.data.name,
            is_admin: data.data.type ? data.data.type == "admin" : false,
            section: data.data.section,
            branch: data.data.branch,
            token: data.data.token,
            companies: data.data.companies || [],
          };
          dispatch({ type: "login", payload: payload_data });
          localStorage.setItem("mtpl_data", JSON.stringify(payload_data.token));
          navigate("/account");
        }
      });
    } else {
      check_login_username(login_data.email);
      check_login_password(login_data.password);
    }
  };

  const check_login_username = (current) => {
    // current.parentNode.className =
    //   "input" + (current.value == "" ? " error" : "");
    // document.getElementById(`${current.id}_err`).innerHTML =
    //   current.value == "" ? "Please enter username" : "";
    if (current.value != "" && login_data.password != "") {
      setlogin_btn(true);
    } else {
      setlogin_btn(false);
    }
  };
  const check_login_password = (current) => {
    // current.parentNode.className =
    //   "input" + (current.value == "" ? " error" : "");
    // document.getElementById(`${current.id}_err`).innerHTML =
    //   current.value == "" ? "Please enter password" : "";
    // current.setAttribute('error', 'true');
    if (current.value != "" && login_data.email != "") {
      setlogin_btn(true);
    } else {
      setlogin_btn(false);
    }
  };

  return (
    <div className="login">
      <div className="main_txt f-sen">
        <div className="first_line">
          <img src={inverted_commas_left} />
          AIMING TO MAKE
        </div>
        {[
          "SUCCESSFUL AND POSITIVE",
          "CONTRIBUTIONS IN THE",
          "INDIAN PLUMBING INDUSTRY FOR",
          "YOUR HOMES & OFFICES.",
        ].map((el, i) => (
          <div key={i}>{el}</div>
        ))}
      </div>
      <div className="login_form">
        {/* <form onSubmit={login}> */}

        <div className="login_form_heading f-sen">SIGN IN</div>
        <div>
          <label htmlFor="username">Username</label>
          <div className="input">
            <img className="err_img" src={error} />
            <input
              id="username"
              type="email"
              value={login_data.email}
              onChange={(e) => {
                setlogin_data((prev) => ({ ...prev, email: e.target.value }));
                check_login_username(e.target);
                setlogin_err(false);
              }}
            />
            {/* <div className="username_err error" id="username_err"></div> */}
            {login_err && (
              <div className="username_err error">
                Please check for typo or email format as name@email.com
              </div>
            )}
          </div>
        </div>
        <div>
          <label htmlFor="password">Password</label>
          <div className="input">
            <img className="err_img" src={error} />
            <input
              id="password"
              type="password"
              value={login_data.password}
              onChange={(e) => {
                setlogin_data((prev) => ({
                  ...prev,
                  password: e.target.value,
                }));
                check_login_password(e.target);
                setlogin_err(false);
              }}
            ></input>
            {/* <div className="password_err error" id="password_err"></div> */}
            {login_err && (
              <div className="username_err error">
                Password Incorrect. Please check for typo.
              </div>
            )}
          </div>
        </div>
        {/* <div className='log_in' onClick={login}>Log me in!</div> */}
        <div id="login_btn">
          {login_btn ? (
            <div className="log_in active" onClick={login}>
              Log me in!
            </div>
          ) : (
            <div className="log_in">Log me in!</div>
          )}
        </div>
        <input type="submit" style={{ display: "none" }}></input>
      </div>
      <Toast msg={toast_msg} setmsg={settoast_msg} />
    </div>
  );
};
