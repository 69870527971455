import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { update_employee } from "../../Api/api";
import { Dropdown } from "../../Component/Custom/Dropdown/Dropdown";

const compare_arr = (arr1, arr2) => {
  if (arr1.length !== arr2.length) {
    return true;
  }
  let temp_arr_holder = [];
  arr1.map((el) => {
    if (temp_arr_holder.includes(el)) {
      temp_arr_holder = temp_arr_holder.filter((el1) => el1 !== el);
    } else {
      temp_arr_holder.push(el);
    }
  });
  arr2.map((el) => {
    if (temp_arr_holder.includes(el)) {
      temp_arr_holder = temp_arr_holder.filter((el1) => el1 !== el);
    } else {
      temp_arr_holder.push(el);
    }
  });

  return temp_arr_holder.length != 0;
};

export const EditUser = ({
  setshow_table_employee,
  section,
  current_selected_employee,
  RolesDropdown,
  BranchesDropdown,
  close,
}) => {
  // console.log("current_selected_employee", current_selected_employee);
  // console.log("BranchesDropdown", BranchesDropdown);
  const current_store = useSelector((state) => state);

  const [page_data, setpage_data] = useState({
    name: "",
    email: "",
    mobile: "",
    password: "",
    address: "",
    type: 0,
    branch: 0,
    section: [],
  });
  // console.log(page_data);
  const [page_err, setpage_err] = useState({
    name_err: "",
    mobile_err: "",
    email_err: "",
    password_err: "",
    usertype_err: "",
    branch_err: "",
  });
  const [save_btn, setsave_btn] = useState(false);
  const handel_save = () => {
    if (save_btn) {
      update_employee(
        {
          id: current_selected_employee._id,
          name: page_data.name,
          mobile: page_data.mobile,
          email: page_data.email,
          address: page_data.address,
          type: page_data.type,
          branch: [page_data.branch],
          section: page_data.section,
          token: current_store.user.token,
        },
        (data) => {
          // console.log(data);
          if (data.status) {
            setshow_table_employee([...data.employee_data]);
            close();
          }
          // return window.location.reload();
        }
      );
    }
  };
  useEffect(() => {
    let { name, email, mobile, password, address, type, branch, section } =
      page_data;
    let {
      name_err,
      mobile_err,
      email_err,
      password_err,
      usertype_err,
      branch_err,
    } = page_err;
    // console.log(branch);
    if (
      name !== "" &&
      // email !== "" &&
      // email_err == "" &&
      mobile !== "" &&
      mobile_err == "" &&
      // password !== "" &&
      // password_err == "" &&
      type !== 0
      // type !== current_selected_employee.type
    ) {
      if (
        name !== current_selected_employee.name ||
        mobile !== current_selected_employee.mobile ||
        address !== current_selected_employee.address ||
        type !== current_selected_employee.type ||
        branch !== current_selected_employee.branch[0]._id ||
        compare_arr(
          section,
          current_selected_employee.section.map((el) => el._id)
        )

        // ||
        // type !== current_selected_employee.
      ) {
        // if(type!==)

        switch (type) {
          case "admin":
            return setsave_btn(true);
          case "branchmanager":
            if (branch == 0) {
              return setsave_btn(false);
            } else {
              return setsave_btn(true);
            }
          case "employee":
            if (section.length > 0 && branch !== 0) {
              return setsave_btn(true);
            } else {
              return setsave_btn(false);
            }
          default:
            break;
        }
        return setsave_btn(true);
      } else {
        return setsave_btn(false);
      }
    } else {
      return setsave_btn(false);
    }
  }, [page_data, page_err]);
  useEffect(() => {
    // console.log(current_selected_employee);
    setpage_data({
      ...page_data,
      name: current_selected_employee.name,
      email: current_selected_employee.email,
      mobile: current_selected_employee.mobile,
      // password: Math.,
      address: current_selected_employee.address,
      type: current_selected_employee.type,
      branch: current_selected_employee.branch[0]._id,
      section: current_selected_employee.section.map((el) => el._id),
    });
  }, []);

  // console.log("edit employee", page_data);
  // console.log(BranchesDropdown);
  return (
    <div
      className="adduser f-rale"
      id="edituser"
      onClick={({ target }) => {
        if (target.id == "edituser") {
          close();
        }
      }}
    >
      <div>
        <div className="header c-red f-sen">Edit Employee</div>
        <div className="body">
          <div className="input_group">
            <div className="input">
              <div className="input_txt">Name</div>
              <input
                type="text"
                value={page_data.name}
                onChange={({ target }) => {
                  setpage_data((prev) => ({ ...prev, name: target.value }));
                  if (target.value == "") {
                    setpage_err((prev) => ({
                      ...prev,
                      name_err: "Please enter name",
                    }));
                  } else {
                    setpage_err((prev) => ({
                      ...prev,
                      name_err: "",
                    }));
                  }
                }}
              />
              <div className="error">{page_err.name_err}</div>
            </div>
            <div className="input">
              <div className="input_txt">Mobile No.</div>
              <input
                type="number"
                value={page_data.mobile}
                onChange={({ target }) => {
                  setpage_data((prev) => ({ ...prev, mobile: target.value }));
                  if (target.value == "") {
                    return setpage_err((prev) => ({
                      ...prev,
                      mobile_err: "Please enter mobile no.",
                    }));
                  } else if (target.value.length != 10) {
                    return setpage_err((prev) => ({
                      ...prev,
                      mobile_err: "Mobile No should be 10 digits",
                    }));
                  } else {
                    return setpage_err((prev) => ({
                      ...prev,
                      mobile_err: "",
                    }));
                  }
                }}
              />
              <div className="error">{page_err.mobile_err}</div>
            </div>
            <div className="input">
              <div className="input_txt">Email</div>
              <input
                type="text"
                readOnly
                value={page_data.email}
                // onChange={({ target }) => {
                //   setpage_data((prev) => ({ ...prev, email: target.value }));
                //   let value_arr = [...target.value];
                //   if (target.value == "") {
                //     return setpage_err((prev) => ({
                //       ...prev,
                //       email_err: "Please enter Email",
                //     }));
                //   } else if (
                //     value_arr.indexOf("@") !== -1 &&
                //     value_arr.indexOf(".") !== -1 &&
                //     value_arr.indexOf("@") < value_arr.indexOf(".") &&
                //     value_arr.indexOf("@") > 0 &&
                //     value_arr.indexOf(".") < value_arr.length - 2
                //   ) {
                //     return setpage_err((prev) => ({
                //       ...prev,
                //       email_err: "",
                //     }));
                //   } else {
                //     return setpage_err((prev) => ({
                //       ...prev,
                //       email_err: "Email not completed",
                //     }));
                //   }
                // }}
              />
              <div className="error">{page_err.email_err}</div>
            </div>
            {/* <div className="input">
              <div className="input_txt">Password</div>
              <input
                type="text"
                value={page_data.password}
                onChange={({ target }) => {
                  setpage_data((prev) => ({ ...prev, password: target.value }));
                  if (target.value == "") {
                    return setpage_err((prev) => ({
                      ...prev,
                      password_err: "Please enter Password.",
                    }));
                  } else if (target.value.length <= 5) {
                    return setpage_err((prev) => ({
                      ...prev,
                      password_err: "6 charecter or more",
                    }));
                  } else {
                    return setpage_err((prev) => ({
                      ...prev,
                      password_err: "",
                    }));
                  }
                }}
              />
              <div className="error">{page_err.password_err}</div>
            </div> */}
          </div>
          <div>
            <div className="input">
              <div className="input_txt">Address</div>
              <textarea
                rows="5"
                value={page_data.address}
                onChange={({ target }) => {
                  setpage_data((prev) => ({ ...prev, address: target.value }));
                }}
              />
            </div>
          </div>
          <div className="input_group">
            <div>
              <div className="input">
                <div className="input_txt">Select User Type</div>
                <Dropdown
                  {...{
                    option_arr: [
                      {
                        id: 0,
                        value: "Select One...",
                      },
                      ...RolesDropdown.option_arr.slice(1),
                    ],
                    current_value: page_data.type,
                    setcurrent_value: (data) => {
                      if (data === 0) {
                        setpage_err((prev) => ({
                          ...prev,
                          usertype_err: "Please select user type",
                        }));
                      } else {
                        setpage_err((prev) => ({
                          ...prev,
                          usertype_err: "",
                        }));
                      }
                      setpage_data((prev) => ({
                        ...prev,
                        type: data,
                      }));
                    },
                    error: !!page_err.usertype_err,
                  }}
                />
                <div className="error">{page_err.usertype_err}</div>
              </div>
            </div>
            {(page_data.type == "branchmanager" ||
              page_data.type == "employee") && (
              <div>
                <div className="input">
                  <div className="input_txt">Select Branch</div>
                  <Dropdown
                    {...{
                      option_arr: [
                        {
                          id: 0,
                          value: "Select One...",
                        },
                        ...BranchesDropdown.option_arr.slice(1),
                      ],
                      current_value: page_data.branch,
                      setcurrent_value: (data) => {
                        if (data === 0) {
                          setpage_err((prev) => ({
                            ...prev,
                            branch_err: "Please select Branch",
                          }));
                        } else {
                          setpage_err((prev) => ({
                            ...prev,
                            branch_err: "",
                          }));
                        }
                        setpage_data((prev) => ({
                          ...prev,
                          branch: data,
                        }));
                      },
                      error: !!page_err.branch_err,
                    }}
                  />
                  <div className="error">{page_err.branch_err}</div>
                </div>
              </div>
            )}
          </div>
          {page_data.type == "employee" && (
            <SectionSelect
              options_arr={[
                ...section.map(({ id, value }) => {
                  return {
                    is_selected: (data) => {
                      // console.log(page_data);
                      return page_data.section.includes(data);
                    },
                    select_this: (data) => {
                      let temp_selection = [...page_data.section];
                      if (temp_selection.includes(data)) {
                        temp_selection = temp_selection.filter(
                          (el) => el !== data
                        );
                      } else {
                        temp_selection = [...temp_selection, data];
                      }
                      setpage_data((prev) => ({
                        ...prev,
                        section: [...temp_selection],
                      }));
                    },
                    selection: id,
                    name: value,
                  };
                }),
              ]}
              current_value={page_data.section}
              setcurrent_value={(data) => {
                setpage_data((prev) => ({ ...prev }));
              }}
            />
          )}
        </div>
        <div className="footer">
          <div onClick={() => close()}>Cancel</div>
          <div className="btn_group">
            <div className={"btn_normal delete"}>Delete</div>
            <div
              className={"btn_normal" + (save_btn ? "" : " active")}
              onClick={() => handel_save()}
            >
              Save
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
const SectionSelect = ({ options_arr, current_value }) => {
  return (
    <div className="section_select">
      <div>Select Section</div>
      <div className="section_select_group">
        {options_arr.map((el, i) => (
          <div
            key={i}
            className="selection_item"
            onClick={() => el.select_this(el.selection)}
          >
            <input
              type="checkbox"
              checked={el.is_selected(el.selection)}
              readOnly
            />
            <div>{el.name}</div>
          </div>
        ))}
      </div>
      <div className="error">
        {current_value.length >= 0 ? "" : "Select atleast one"}
      </div>
    </div>
  );
};
