import React, { useEffect, useState } from "react";
import { Calendar } from "react-date-range";
import "./styles.css"; // main css file
import "./default.css"; // theme css file
const format_num = (num) => {
  if (num < 10) {
    return `0${num}`;
  } else {
    return num;
  }
};
export const DatePicker = ({
  setcurrent_value,
  current_value,
  clear,
  readonly,
}) => {
  const [date, setDate] = useState(null);
  const [is_open, setis_open] = useState(false);
  useEffect(() => {
    // if (current_value !== date) {
    setDate(current_value);
    // }
  }, [current_value]);
  // useEffect(() => {
  // }, [date]);

  if (readonly) {
    return (
      <div
        readonly="true"
        className="date_picker"
        onMouseLeave={() => {
          setis_open(false);
        }}
      >
        <div className="date_txt">Date</div>
        <div className="main_txt" onClick={() => setis_open(!is_open)}>
          {date
            ? `${format_num(date.getDate())}-${format_num(
                date.getMonth() + 1
              )}-${format_num(date.getFullYear())}`
            : "dd-mm-yyyy"}
        </div>
      </div>
    );
  }

  return (
    <div
      className="date_picker"
      onMouseLeave={() => {
        setis_open(false);
      }}
    >
      <div className="date_txt">Date</div>
      <div className="main_txt" onClick={() => setis_open(!is_open)}>
        {date
          ? `${format_num(date.getDate())}-${format_num(
              date.getMonth() + 1
            )}-${format_num(date.getFullYear())}`
          : "dd-mm-yyyy"}
      </div>
      {is_open && (
        <div className="cust_calender_main">
          <Calendar
            className={"cust_calender"}
            onChange={(item) => {
              setDate(item);
              setcurrent_value(item);
              setis_open(false);
            }}
            date={date}
          />
          <div className="btn_group">
            {!clear ? (
              <div
                onClick={() => {
                  setDate(null);
                  setcurrent_value(null);
                  setis_open(false);
                }}
              >
                Clear
              </div>
            ) : (
              ""
            )}
            <div
              onClick={() => {
                setDate(new Date());
                setcurrent_value(new Date());
                setis_open(false);
              }}
            >
              Today
            </div>
          </div>
        </div>
      )}
      <div className="date_icon">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          version="1.1"
          xlink="http://www.w3.org/1999/xlink"
          svgjs="http://svgjs.com/svgjs"
          width="13"
          height="13"
          x="0"
          y="0"
          viewBox="0 0 512 512"
          style={{ enableBackground: "new 0 0 512 512" }}
          space="preserve"
        >
          <g>
            <g>
              <path
                d="m446 40h-46v-24c0-8.836-7.163-16-16-16s-16 7.164-16 16v24h-224v-24c0-8.836-7.163-16-16-16s-16 7.164-16 16v24h-46c-36.393 0-66 29.607-66 66v340c0 36.393 29.607 66 66 66h380c36.393 0 66-29.607 66-66v-340c0-36.393-29.607-66-66-66zm34 406c0 18.778-15.222 34-34 34h-380c-18.778 0-34-15.222-34-34v-265c0-2.761 2.239-5 5-5h438c2.761 0 5 2.239 5 5z"
                fill="#000000"
                data-original="#000000"
              ></path>
            </g>
          </g>
        </svg>
      </div>
    </div>
  );
};
