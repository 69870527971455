import React, { useEffect, useState } from "react";

export const Toast = ({ msg, setmsg }) => {
  // let date = new Date
  // console.log(Date.now());
  // console.log(id);
  // let id = Date.now()
  let id = "toast";

  const [toaststate, settoaststate] = useState(false);

  useEffect(() => {
    if (msg != "") {
      document.getElementById(id).className = "toast_cust active";
      setTimeout(() => {
        document.getElementById(id).className = "toast_cust";
        setTimeout(() => {
          let toast = document.getElementById(id);
          setmsg("");
          // toast.parentNode.removeChild(toast);
        }, 600);
      }, 3000);
      // let toast_cust = document.getElementsByClassName('toast_cust')[0]
      // console.log(toast_cust);
      // toast_cust[]
    }
  }, [msg]);

  return (
    <div className="toast_cust" id={"toast"}>
      {msg}
      {/* <img src={close}/> */}
      <div
        onClick={() => (document.getElementById(id).className = "toast_cust")}
      >
        X
      </div>
    </div>
  );
};
