import { useState } from "react";
import { Provider, useSelector } from "react-redux";
import { useRoutes } from "react-router-dom";
import { Navbar } from "./Component/Navbar";
import { Account } from "./Pages/Account";
import { Login } from "./Pages/Login";
import { Settings } from "./Pages/Settings";
import store from "./store/store";
// import "./app.scss";
import "./style/main.css";
// import "./color.scss";

function App() {
  const [page_status, setpage_status] = useState({
    account: true,
    settings: true,
  });
  const routes = [
    {
      path: "/",
      element: <Login />,
    },
    ...[
      ["/account", page_status.account, <Account />],
      ["/settings", page_status.settings, <Settings />],
    ].map((el) => {
      return {
        path: el[0],
        element: el[1] ? el[2] : <>err</>,
      };
    }),
    {
      path: "/*",
      element: <>err</>,
    },
  ];

  const route = useRoutes(routes);
  return (
    <Provider store={store}>
      <Navbar />
      {route}
    </Provider>
  );
}

export default App;
