import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  check_token,
  delete_item,
  get_employees,
  get_settings,
} from "../Api/api";
import { Table } from "../Component/Custom/Table/Table";
import { SettingsFilter } from "../Component/SettingsFilter";

import arrow_down from "../img/icons/arrow_down_fill.png";
import arrow_up from "../img/icons/arrow_up_fill.png";
import menu_dots from "../img/icons/dots.png";
import { EditBranch } from "../PageComponents/EditBranch/EditBranch";
import { EditCompany } from "../PageComponents/EditCompany/EditCompany";
import { EditUser } from "../PageComponents/EditUser/EditUser";
import { ResetPassword } from "../PageComponents/ResetPassword/ResetPassword";

export const Settings = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const current_store = useSelector((state) => state);
  // console.log(current_store.user);
  const [current_show_table, setcurrent_show_table] = useState("Employee");
  const [page_data, setpage_data] = useState({
    branches: [
      {
        id: 0,
        value: "All Branches",
      },
    ],
    roles: [
      {
        id: 0,
        value: "All",
      },
      {
        id: "admin",
        value: "Admin",
      },
      {
        id: "branchmanager",
        value: "BranchManager",
      },
      {
        id: "employee",
        value: "Employee",
      },
    ],
    section: [],
  });
  const [page_values, setpage_values] = useState({
    selecteed_branch: 0,
    selecteed_role: 0,
  });
  const table_filter_defaults = {
    name: "",
    role: "",
    branch: "",
    userType: "",
    employee: "",
  };
  const [table_filter, settable_filter] = useState({
    ...table_filter_defaults,
  });
  const [deleting, setdeleting] = useState(false);
  const [search, setsearch] = useState("");
  const [setting_menu, setsetting_menu] = useState("");

  const [show_table_employee, setshow_table_employee] = useState([]);
  const [show_table_branch, setshow_table_branch] = useState([]);
  const [show_table_company, setshow_table_company] = useState([]);
  const [current_selected_employee, setcurrent_selected_employee] = useState(
    {}
  );
  const [current_show_modal, setcurrent_show_modal] = useState("");
  // console.log(page_values, search);
  useEffect(() => {
    if (current_store.user.token == "") {
      let local_token = localStorage.getItem("mtpl_data");
      if (!local_token) {
        // dispatch({ type: "logout" });
        navigate("/");
        return;
      } else {
        local_token = JSON.parse(localStorage.getItem("mtpl_data"));
        check_token(
          {
            token: local_token,
          },
          (data) => {
            const payload_data = {
              name: data.data.name,
              is_admin: data.data.type ? data.data.type == "admin" : false,
              section: data.data.section.map((el) => ({
                id: el._id,
                value: el.name,
                options: el.options.map((el1) => ({
                  id: el1._id,
                  value: el1.name,
                })),
              })),
              branch: data.data.branch.map((el) => ({
                id: el._id,
                value: el.name,
              })),
              token: local_token,
              companies: data.data.companies.map((el) => ({
                id: el._id,
                value: el.name,
              })),
            };
            // console.log("settings check token", data.data, payload_data);
            dispatch({ type: "login", payload: payload_data });
            if (data.data.type != "admin") {
              return navigate("/account");
            }
          },
          () => {
            // dispatch({ type: "logout" });
            return navigate("/");
          }
        );
        return;
      }
    } else {
      setpage_data((prev) => ({
        branches: [prev.branches[0], ...current_store.user.branch],
        roles: prev.roles,
        section: [...current_store.user.section],
        companies: [...current_store.user.companies],
      }));
    }
  }, [current_store]);
  // console.log(page_data);
  useEffect(() => {
    if (current_store.user.token != "") {
      // console.log("get_employee", current_store.user.token);
      get_settings({ token: current_store.user.token }, (data) => {
        // console.log("getsettings", data);
        setshow_table_employee([...data.data.employees]);
        setshow_table_branch([
          ...data.data.branches.map((el) => ({
            ...el,
            employee_count: data.data.employees.reduce((prev, curr) => {
              if (curr.type === "admin") {
                return prev;
              } else {
                if (curr.branch[0]._id === el._id) {
                  return prev + 1;
                } else {
                  return prev;
                }
              }
            }, 0),
          })),
        ]);
        setshow_table_company([...data.data.companies]);
      });
    }
  }, [current_store]);
  useEffect(() => {
    if (show_table_employee.length > 0 && show_table_branch.length > 0) {
      setshow_table_branch((prev) => [
        ...prev.map((el) => ({
          ...el,
          employee_count: show_table_employee.reduce((prev, curr) => {
            if (curr.type === "admin") {
              return prev;
            } else {
              if (curr.branch[0]._id === el._id) {
                return prev + 1;
              } else {
                return prev;
              }
            }
          }, 0),
        })),
      ]);
    }
  }, [show_table_employee]);
  // console.log(show_table_branch);
  return (
    <div className="settings">
      <SettingsMainFilter
        current_show_table={current_show_table}
        setcurrent_show_table={setcurrent_show_table}
        reset_filter={() => {
          setpage_values({
            selecteed_branch: 0,
            selecteed_role: 0,
          });
          setsearch("");
          settable_filter({
            ...table_filter_defaults,
          });
          setsetting_menu("");
        }}
      />
      <SettingsFilter
        {...{
          filter_type: current_show_table,
          section: [...page_data?.section],
          BranchesDropdown: {
            option_arr: page_data.branches,
            current_value: page_values.selecteed_branch,
            setcurrent_value: (data) => {
              setpage_values((prev) => ({ ...prev, selecteed_branch: data }));
            },
          },
          RolesDropdown: {
            option_arr: page_data.roles,
            current_value: page_values.selecteed_role,
            setcurrent_value: (data) => {
              setpage_values((prev) => ({ ...prev, selecteed_role: data }));
            },
          },
          search,
          setsearch,
          show_table_employee,
          setshow_table_employee,
        }}
      />
      {current_show_table === "Company" && (
        <Table
          {...{
            cust_class: "settings_table company_table",
            table_header: [
              <div
                className="has_arrows"
                onClick={() => {
                  settable_filter((prev) => ({
                    role: "",
                    branch: "",
                    name:
                      prev.name === "ascending"
                        ? "decending"
                        : prev.name === "decending"
                        ? ""
                        : "ascending",
                  }));
                }}
              >
                Name
                <div className="arrow_group">
                  <img
                    className={table_filter.name == "ascending" ? "active" : ""}
                    src={arrow_up}
                  />
                  <img
                    className={table_filter.name == "decending" ? "active" : ""}
                    src={arrow_down}
                  />
                </div>
              </div>,

              "Action",
            ],
            table_body: show_table_company
              ? [
                  ...show_table_company
                    .sort((a, b) => {
                      if (table_filter.name === "ascending") {
                        return a.name > b.name ? 1 : b.name > a.name ? -1 : 0;
                      }
                      if (table_filter.name === "decending") {
                        return a.name < b.name ? 1 : b.name < a.name ? -1 : 0;
                      }
                      // if (table_filter.role === "ascending") {
                      //   return a.type > b.type ? 1 : b.type > a.type ? -1 : 0;
                      // }
                      // if (table_filter.role === "decending") {
                      //   return a.type < b.type ? 1 : b.type < a.type ? -1 : 0;
                      // }
                      // if (table_filter.branch === "ascending") {
                      //   return a.branch > b.branch
                      //     ? 1
                      //     : b.branch > a.branch
                      //     ? -1
                      //     : 0;
                      // }
                      // if (table_filter.branch === "decending") {
                      //   return a.branch < b.branch
                      //     ? 1
                      //     : b.branch < a.branch
                      //     ? -1
                      //     : 0;
                      // }
                      return true;
                    })
                    .filter((el) => {
                      let { selecteed_role, selecteed_branch } = page_values;
                      // console.log(
                      //   search.toLowerCase().match(el.name.toLowerCase()),
                      //   search,
                      //   el.name
                      // );
                      return (
                        // (el.type === selecteed_role || selecteed_role == 0) &&
                        // (el.branch[0]._id === selecteed_branch ||
                        //   selecteed_branch == 0) &&
                        el.name.toLowerCase().match(search.toLowerCase()) ||
                        search == ""
                      );
                    })
                    .map((el, i) => [
                      el.name,

                      <div
                        className="btn_normal"
                        onClick={() => {
                          setcurrent_selected_employee({ ...el });
                          setsetting_menu("");
                          setcurrent_show_modal("delete_company");
                        }}
                      >
                        Delete
                      </div>,
                    ]),
                ]
              : [],
          }}
        />
      )}
      {current_show_table === "Branch" && (
        <Table
          {...{
            cust_class: "settings_table branch_table",
            table_header: [
              <div
                className="has_arrows"
                onClick={() => {
                  settable_filter((prev) => ({
                    ...table_filter_defaults,
                    name:
                      prev.name === "ascending"
                        ? "decending"
                        : prev.name === "decending"
                        ? ""
                        : "ascending",
                  }));
                }}
              >
                Name
                <div className="arrow_group">
                  <img
                    className={table_filter.name == "ascending" ? "active" : ""}
                    src={arrow_up}
                  />
                  <img
                    className={table_filter.name == "decending" ? "active" : ""}
                    src={arrow_down}
                  />
                </div>
              </div>,
              "Address",
              <div
                className="has_arrows"
                onClick={() => {
                  settable_filter((prev) => ({
                    ...table_filter_defaults,
                    employee:
                      prev.employee === "ascending"
                        ? "decending"
                        : prev.employee === "decending"
                        ? ""
                        : "ascending",
                  }));
                }}
              >
                Employes
                <div className="arrow_group">
                  <img
                    className={
                      table_filter.employee == "ascending" ? "active" : ""
                    }
                    src={arrow_up}
                  />
                  <img
                    className={
                      table_filter.employee == "decending" ? "active" : ""
                    }
                    src={arrow_down}
                  />
                </div>
              </div>,
              "",
            ],
            table_body: show_table_branch
              ? [
                  ...show_table_branch
                    .sort((a, b) => {
                      if (table_filter.name === "ascending") {
                        return a.name > b.name ? 1 : b.name > a.name ? -1 : 0;
                      }
                      if (table_filter.name === "decending") {
                        return a.name < b.name ? 1 : b.name < a.name ? -1 : 0;
                      }
                      if (table_filter.employee === "ascending") {
                        return a.employee_count > b.employee_count
                          ? 1
                          : b.employee_count > a.employee_count
                          ? -1
                          : 0;
                      }
                      if (table_filter.employee === "decending") {
                        return a.employee_count < b.employee_count
                          ? 1
                          : b.employee_count < a.employee_count
                          ? -1
                          : 0;
                      }

                      return true;
                    })
                    .filter((el) => {
                      let { selecteed_role, selecteed_branch } = page_values;
                      // console.log(
                      //   search.toLowerCase().match(el.name.toLowerCase()),
                      //   search,
                      //   el.name
                      // );
                      // console.log(selecteed_branch);
                      return (
                        // (el.type === selecteed_role || selecteed_role == 0) &&
                        // (
                        el._id === selecteed_branch || !selecteed_branch
                        // ||
                        //   selecteed_branch == 0) &&
                        // el.name.toLowerCase().match(search.toLowerCase()) ||
                        // search == ""
                      );
                    })
                    .map((el, i) => [
                      el.name,
                      el.address || <div className="center">-</div>,
                      <div className="center">{el.employee_count || "-"}</div>,
                      <div
                        className="btn_normal"
                        onClick={() => {
                          setcurrent_selected_employee({ ...el });
                          setsetting_menu("");
                          setcurrent_show_modal("delete_branch");
                        }}
                      >
                        Delete
                      </div>,
                      // <div className="employee_menu">
                      //   <img
                      //     src={menu_dots}
                      //     onClick={() =>
                      //       setsetting_menu((prev) =>
                      //         prev === `setting_menu${i}`
                      //           ? ""
                      //           : `setting_menu${i}`
                      //       )
                      //     }
                      //   />
                      //   <div
                      //     className={
                      //       "menu_main" +
                      //       (setting_menu === `setting_menu${i}`
                      //         ? " active"
                      //         : "")
                      //     }
                      //   >
                      //     <div
                      //       onClick={() => {
                      //         setcurrent_selected_employee({ ...el });
                      //         setsetting_menu("");
                      //         setcurrent_show_modal("edit_branch");
                      //       }}
                      //     >
                      //       Edit
                      //     </div>
                      //     <div
                      //       onClick={() => {
                      //         setcurrent_selected_employee({ ...el });
                      //         setsetting_menu("");
                      //         setcurrent_show_modal("delete_branch");
                      //       }}
                      //     >
                      //       Delete
                      //     </div>
                      //   </div>
                      // </div>,
                    ]),
                ]
              : [],
          }}
        />
      )}
      {current_show_table === "Employee" && (
        <Table
          {...{
            cust_class: "settings_table employee_table",
            table_header: [
              <div
                className="has_arrows"
                onClick={() => {
                  settable_filter((prev) => ({
                    role: "",
                    branch: "",
                    name:
                      prev.name === "ascending"
                        ? "decending"
                        : prev.name === "decending"
                        ? ""
                        : "ascending",
                  }));
                }}
              >
                Name
                <div className="arrow_group">
                  <img
                    className={table_filter.name == "ascending" ? "active" : ""}
                    src={arrow_up}
                  />
                  <img
                    className={table_filter.name == "decending" ? "active" : ""}
                    src={arrow_down}
                  />
                </div>
              </div>,
              "Email",
              <div
                className="has_arrows"
                onClick={() => {
                  settable_filter((prev) => ({
                    name: "",
                    branch: "",
                    role:
                      prev.role === "ascending"
                        ? "decending"
                        : prev.role === "decending"
                        ? ""
                        : "ascending",
                  }));
                }}
              >
                Role
                <div className="arrow_group">
                  <img
                    className={table_filter.role == "ascending" ? "active" : ""}
                    src={arrow_up}
                  />
                  <img
                    className={table_filter.role == "decending" ? "active" : ""}
                    src={arrow_down}
                  />
                </div>
              </div>,
              <div
                className="has_arrows"
                onClick={() => {
                  settable_filter((prev) => ({
                    name: "",
                    role: "",
                    branch:
                      prev.branch === "ascending"
                        ? "decending"
                        : prev.branch === "decending"
                        ? ""
                        : "ascending",
                  }));
                }}
              >
                Branch
                <div className="arrow_group">
                  <img
                    className={
                      table_filter.branch == "ascending" ? "active" : ""
                    }
                    src={arrow_up}
                  />
                  <img
                    className={
                      table_filter.branch == "decending" ? "active" : ""
                    }
                    src={arrow_down}
                  />
                </div>
              </div>,
              "Action",
            ],
            table_body: show_table_employee
              ? [
                  ...show_table_employee
                    .sort((a, b) => {
                      if (table_filter.name === "ascending") {
                        return a.name > b.name ? 1 : b.name > a.name ? -1 : 0;
                      }
                      if (table_filter.name === "decending") {
                        return a.name < b.name ? 1 : b.name < a.name ? -1 : 0;
                      }
                      if (table_filter.role === "ascending") {
                        return a.type > b.type ? 1 : b.type > a.type ? -1 : 0;
                      }
                      if (table_filter.role === "decending") {
                        return a.type < b.type ? 1 : b.type < a.type ? -1 : 0;
                      }
                      if (table_filter.branch === "ascending") {
                        return a.branch > b.branch
                          ? 1
                          : b.branch > a.branch
                          ? -1
                          : 0;
                      }
                      if (table_filter.branch === "decending") {
                        return a.branch < b.branch
                          ? 1
                          : b.branch < a.branch
                          ? -1
                          : 0;
                      }
                      return true;
                    })
                    .filter((el) => {
                      let { selecteed_role, selecteed_branch } = page_values;
                      // console.log(
                      //   search.toLowerCase().match(el.name.toLowerCase()),
                      //   search,
                      //   el.name
                      // );
                      return (
                        (el.type === selecteed_role || selecteed_role == 0) &&
                        (el.branch[0]._id === selecteed_branch ||
                          selecteed_branch == 0) &&
                        (el.name.toLowerCase().match(search.toLowerCase()) ||
                          search == "")
                      );
                    })
                    .map((el, i) => [
                      el.name,
                      el.email,
                      el.type[0].toUpperCase() + el.type.slice(1),
                      el.type === "admin" ? "All" : el.branch[0].name,
                      <div className="employee_menu">
                        <img
                          src={menu_dots}
                          onClick={() =>
                            setsetting_menu((prev) =>
                              prev === `setting_menu${i}`
                                ? ""
                                : `setting_menu${i}`
                            )
                          }
                        />
                        <div
                          className={
                            "menu_main" +
                            (setting_menu === `setting_menu${i}`
                              ? " active"
                              : "")
                          }
                        >
                          <div
                            onClick={() => {
                              setcurrent_selected_employee({ ...el });
                              setsetting_menu("");
                              setcurrent_show_modal("edit_employee");
                            }}
                          >
                            Edit
                          </div>
                          <div
                            onClick={() => {
                              setcurrent_selected_employee({ ...el });
                              setsetting_menu("");
                              setcurrent_show_modal("resetpassword_employee");
                            }}
                          >
                            Reset Password
                          </div>
                          <div
                            onClick={() => {
                              setcurrent_selected_employee({ ...el });
                              setsetting_menu("");
                              setcurrent_show_modal("delete_employee");
                            }}
                          >
                            Delete
                          </div>
                        </div>
                      </div>,
                    ]),
                ]
              : [],
          }}
        />
      )}
      {current_show_modal === "edit_employee" && (
        <EditUser
          {...{
            section: [...page_data?.section],
            current_selected_employee: current_selected_employee,
            BranchesDropdown: {
              option_arr: page_data.branches,
              current_value: page_values.selecteed_branch,
              setcurrent_value: (data) => {
                setpage_values((prev) => ({ ...prev, selecteed_branch: data }));
              },
            },
            RolesDropdown: {
              option_arr: page_data.roles,
              current_value: page_values.selecteed_role,
              setcurrent_value: (data) => {
                setpage_values((prev) => ({ ...prev, selecteed_role: data }));
              },
            },
            close: () => {
              setcurrent_selected_employee({});
              setcurrent_show_modal("");
            },
            setshow_table_employee,
          }}
        />
      )}
      {current_show_modal === "resetpassword_employee" && (
        <ResetPassword
          {...{
            current_selected_employee: current_selected_employee,
            close: () => {
              setcurrent_selected_employee({});
              setcurrent_show_modal("");
            },
          }}
        />
      )}
      {(current_show_modal === "delete_branch" ||
        current_show_modal === "delete_company" ||
        current_show_modal === "delete_employee") && (
        <div
          className="delete_modal"
          id="delete_modal"
          onClick={({ target }) =>
            target.id === "delete_modal" ? setcurrent_show_modal("") : ""
          }
        >
          <div className="f-sen">
            <div className="txt">
              Are you sure you want to delete this{" "}
              {current_show_modal === "delete_branch"
                ? "Branch"
                : current_show_modal === "delete_company"
                ? "Company"
                : "Employee"}{" "}
              ?
            </div>
            <div class="txt2">This action cannot be undone!</div>
            <div className="btn_group">
              <div
                className="f-rel"
                style={{
                  fontSize: 18,
                  lineHeight: 1,
                  color: "#000",
                }}
                onClick={() => setcurrent_show_modal("")}
              >
                Cancel
              </div>
              <div
                className={"btn_normal" + (deleting ? " active" : "")}
                onClick={() => {
                  setdeleting(true);
                  console.log(
                    "delete",
                    current_show_modal,
                    current_selected_employee
                  );
                  if (current_show_modal === "delete_branch") {
                    delete_item({
                      id: current_selected_employee._id,
                      token: current_store.user.token,
                      type: "branch",
                    });
                  } else if (current_show_modal === "delete_company") {
                    delete_item({
                      id: current_selected_employee._id,
                      token: current_store.user.token,
                      type: "company",
                    });
                  } else {
                    delete_item({
                      id: current_selected_employee._id,
                      token: current_store.user.token,
                      type: "employee",
                    });
                  }
                }}
              >
                {deleting ? "Deleting..." : "Delete"}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
const SettingsMainFilter = ({
  reset_filter,
  current_show_table,
  setcurrent_show_table,
}) => {
  useEffect(() => {
    let nav = localStorage.getItem("settings_nav");
    if (nav) {
      setcurrent_show_table(nav);
    }
  }, []);
  return (
    <div className="settingsmainfilter">
      {["Company", "Branch", "Employee"].map((el) => (
        <div
          className={
            "filter_item" + (current_show_table === el ? " active" : "")
          }
          onClick={() => {
            setcurrent_show_table(el);
            reset_filter();
            localStorage.setItem("settings_nav", el);
          }}
        >
          {el}
        </div>
      ))}
    </div>
  );
};
