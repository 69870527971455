import React from "react";

export const Table = ({
  cust_class,
  table_header,
  table_body,
  accounts_condition,
}) => {
  return (
    <div className={"cust_table" + (cust_class ? ` ${cust_class}` : "")}>
      <div className="table_header">
        {table_header.map((el) => (
          <div>{el}</div>
        ))}
      </div>
      <div className="table_body">
        {table_body.length > 0 ? (
          table_body.map((el) => (
            <div
              className="row"
              deleted={
                accounts_condition ? (el[0] == "temp").toString() : false
              }
            >
              {el.map((el1, i) => {
                if (accounts_condition) {
                  if (i == 0) {
                    return "";
                  } else {
                    return <div data={i}>{el1}</div>;
                  }
                }
                return <div data={i}>{el1}</div>;
              })}
            </div>
          ))
        ) : (
          <div className="center" style={{ margin: "30px 0 0 0" }}>
            No Data
          </div>
        )}
      </div>
    </div>
  );
};
