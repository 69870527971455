import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { update_employee, update_employee_password } from "../../Api/api";
import { Dropdown } from "../../Component/Custom/Dropdown/Dropdown";

const compare_arr = (arr1, arr2) => {
  if (arr1.length !== arr2.length) {
    return true;
  }
  let temp_arr_holder = [];
  arr1.map((el) => {
    if (temp_arr_holder.includes(el)) {
      temp_arr_holder = temp_arr_holder.filter((el1) => el1 !== el);
    } else {
      temp_arr_holder.push(el);
    }
  });
  arr2.map((el) => {
    if (temp_arr_holder.includes(el)) {
      temp_arr_holder = temp_arr_holder.filter((el1) => el1 !== el);
    } else {
      temp_arr_holder.push(el);
    }
  });

  return temp_arr_holder.length != 0;
};

export const ResetPassword = ({ current_selected_employee, close }) => {
  // console.log("current_selected_employee", current_selected_employee);
  // console.log("BranchesDropdown", BranchesDropdown);
  const current_store = useSelector((state) => state);

  const [page_data, setpage_data] = useState({
    email: "",
    password: "",
    confirmpassword: "",
  });
  // console.log(page_data);
  const [page_err, setpage_err] = useState({
    name_err: "",
    mobile_err: "",
    email_err: "",
    password_err: "",
    usertype_err: "",
    branch_err: "",
    confirmpassword_err: "",
  });
  const [save_btn, setsave_btn] = useState(false);
  const handel_save = () => {
    if (save_btn) {
      update_employee_password(
        {
          id: current_selected_employee._id,
          email: current_selected_employee.email,
          password: page_data.password,
          token: current_store.user.token,
        },
        (data) => {
          console.log(data);
          close();
          return window.location.reload();
        }
      );
    }
  };
  useEffect(() => {
    let { password, confirmpassword } = page_data;
    if (
      password !== "" &&
      password.length >= 6 &&
      password === confirmpassword
    ) {
      return setsave_btn(true);
    } else {
      return setsave_btn(false);
    }
  }, [page_data, page_err]);
  useEffect(() => {
    // console.log(current_selected_employee);
    setpage_data({
      ...page_data,
      email: current_selected_employee.email,
    });
  }, []);

  // console.log("edit employee", page_data);
  // console.log(BranchesDropdown);
  return (
    <div
      className="adduser f-rale"
      id="edituser"
      onClick={({ target }) => {
        if (target.id == "edituser") {
          close();
        }
      }}
    >
      <div>
        <div className="header c-red f-sen">Reset Password</div>
        <div className="body">
          <div className="input_group" style={{ justifyContent: "center" }}>
            <div className="input" style={{ width: "100%" }}>
              <div className="input_txt">Email</div>
              <input type="text" readOnly value={page_data.email} />
              <div className="error">{page_err.email_err}</div>
            </div>
          </div>
          <div className="input_group" style={{ justifyContent: "center" }}>
            <div className="input" style={{ width: "100%" }}>
              <div className="input_txt">Password</div>
              <input
                type="text"
                value={page_data.password}
                onChange={({ target }) => {
                  if (target.value.length < 6) {
                    setpage_err((prev) => ({
                      ...prev,
                      password_err: "6 character or more",
                    }));
                  } else {
                    setpage_err((prev) => ({
                      ...prev,
                      password_err: "",
                    }));
                  }
                  setpage_data((prev) => ({ ...prev, password: target.value }));
                }}
              />
              <div className="error">{page_err.password_err}</div>
            </div>
          </div>
          <div className="input_group" style={{ justifyContent: "center" }}>
            <div className="input" style={{ width: "100%" }}>
              <div className="input_txt">Confirm Password</div>
              <input
                type="text"
                value={page_data.confirmpassword}
                onChange={({ target }) => {
                  if (page_data.password !== target.value) {
                    setpage_err((prev) => ({
                      ...prev,
                      confirmpassword_err: "password does not match",
                    }));
                  } else {
                    setpage_err((prev) => ({
                      ...prev,
                      confirmpassword_err: "",
                    }));
                  }
                  setpage_data((prev) => ({
                    ...prev,
                    confirmpassword: target.value,
                  }));
                }}
              />
              <div className="error">{page_err.confirmpassword_err}</div>
            </div>
          </div>
        </div>
        <div className="footer">
          <div onClick={() => close()}>Cancel</div>

          <div
            className={"btn_normal" + (save_btn ? "" : " active")}
            onClick={() => handel_save()}
          >
            Save
          </div>
        </div>
      </div>
    </div>
  );
};
