import React, { useState } from "react";
import { Addbranch } from "../PageComponents/AddBranch/AddBranch";
import { AddCompany } from "../PageComponents/AddCompany/AddCompany";
import { AddUser } from "../PageComponents/AddUser/AddUser";
import { Dropdown } from "./Custom/Dropdown/Dropdown";
import { Search } from "./Custom/Search/Search";

export const SettingsFilter = ({
  filter_type,
  section,
  RolesDropdown,
  BranchesDropdown,
  search,
  setsearch,
  show_table_employee,
  setshow_table_employee,
}) => {
  // console.log(RolesDropdown, BranchesDropdown);
  const [adduser_modal, setadduser_modal] = useState(false);
  return (
    <div className="setings_filter f-sen" type={filter_type.toLowerCase()}>
      <div className="txt">{filter_type}</div>
      {filter_type === "Company" && (
        <>
          <div className="filter_group">
            {/* <Dropdown {...RolesDropdown} /> */}
            {/* <Dropdown {...BranchesDropdown} /> */}
            <Search
              {...{ current_value: search, setcurrent_value: setsearch }}
            />
          </div>
          <div
            className="btn"
            onClick={() => {
              setadduser_modal(true);
            }}
          >
            Add Company
          </div>
          {adduser_modal && (
            <AddCompany
              {...{
                section,
                RolesDropdown,
                BranchesDropdown,
                close: () => {
                  setadduser_modal(false);
                },
              }}
            />
          )}
        </>
      )}
      {filter_type === "Branch" && (
        <>
          <div className="filter_group">
            {/* <Dropdown {...RolesDropdown} /> */}
            <Dropdown {...BranchesDropdown} />
            {/* <Search
              {...{ current_value: search, setcurrent_value: setsearch }}
            /> */}
          </div>
          <div
            className="btn"
            onClick={() => {
              setadduser_modal(true);
            }}
          >
            Add Branch
          </div>
          {adduser_modal && (
            <Addbranch
              {...{
                section,
                RolesDropdown,
                BranchesDropdown,
                close: () => {
                  setadduser_modal(false);
                },
              }}
            />
          )}
        </>
      )}
      {filter_type === "Employee" && (
        <>
          <div className="filter_group">
            <Dropdown {...RolesDropdown} />
            <Dropdown {...BranchesDropdown} />
            <Search
              {...{ current_value: search, setcurrent_value: setsearch }}
            />
          </div>
          <div
            className="btn"
            onClick={() => {
              setadduser_modal(true);
            }}
          >
            Add User
          </div>
          {adduser_modal && (
            <AddUser
              {...{
                show_table_employee,
                setshow_table_employee,
                section,
                RolesDropdown,
                BranchesDropdown,
                close: () => {
                  setadduser_modal(false);
                },
              }}
            />
          )}
        </>
      )}
    </div>
  );
};
