import { tab } from "@testing-library/user-event/dist/tab";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  check_token,
  delete_item,
  get_file_size,
  get_invoice,
  get_links,
  humanFileSize,
  save_invoice,
  update_invoice,
} from "../Api/api";
import { CompanyYearSelector } from "../Component/CompanyYearSelector";
import { DatePicker } from "../Component/Custom/DatePicker/DatePicker";
import { Dropdown } from "../Component/Custom/Dropdown/Dropdown";
import { Search } from "../Component/Custom/Search/Search";
import { Table } from "../Component/Custom/Table/Table";

import arrow_down from "../img/icons/arrow_down_fill.png";
import arrow_up from "../img/icons/arrow_up_fill.png";
import add from "../img/icons/add.svg";
import attach from "../img/icons/attach.svg";
import PDFMerger from "pdf-merger-js";
import moment from "moment/moment";

export const Account = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const current_store = useSelector((state) => state);
  const [page_data, setpage_data] = useState({
    branches: [
      {
        id: 0,
        value: "All Branches",
      },
    ],
    roles: [
      {
        id: 0,
        value: "All",
      },
      {
        id: "admin",
        value: "Admin",
      },
      {
        id: "branchmanager",
        value: "BranchManager",
      },
      {
        id: "employee",
        value: "Employee",
      },
    ],
    section: [],
    companies: [],
  });
  const [loading, setloading] = useState(false);
  const [to_show_table, setto_show_table] = useState([]);
  const [current_invoice, setcurrent_invoice] = useState({});
  const [editinvoice_modal, seteditinvoice_modal] = useState(false);
  const [hard_delete, sethard_delete] = useState(false);
  const [table_filters, settable_filters] = useState({
    company: "",
    year:
      new Date().getMonth() >= 3
        ? new Date().getFullYear()
        : new Date().getFullYear() - 1,
    branch_dropdown: 0,
    type: "",

    invoicenumber: "",
    lastediteddby: "",
    search: "",
    branch: "",
    date: null,
  });

  // console.log(page_data);
  // console.log(table_filters);
  useEffect(() => {
    if (current_store.user.token == "") {
      let local_token = localStorage.getItem("mtpl_data");
      if (!local_token) {
        // dispatch({ type: "logout" });
        navigate("/");
        return;
      } else {
        local_token = JSON.parse(localStorage.getItem("mtpl_data"));
        check_token(
          {
            token: local_token,
          },
          (data) => {
            const payload_data = {
              name: data.data.name,
              is_admin: data.data.type ? data.data.type == "admin" : false,
              section: data.data.section.map((el) => ({
                id: el._id,
                value: el.name,
                options: el.options.map((el1) => ({
                  id: el1._id,
                  value: el1.name,
                })),
              })),
              branch: data.data.branch.map((el) => ({
                id: el._id,
                value: el.name,
              })),
              token: local_token,
              companies: data.data.companies.map((el) => ({
                id: el._id,
                value: el.name,
              })),
            };
            console.log("payload_data", data.data, payload_data);
            dispatch({ type: "login", payload: payload_data });
          },
          () => {
            console.log("here");

            return navigate("/");
          }
        );
        return;
      }
    } else {
      setpage_data((prev) => ({
        branches: [prev.branches[0], ...current_store.user.branch],
        roles: prev.roles,
        section: [...current_store.user.section],
        companies: [...current_store.user.companies],
      }));
      settable_filters((prev) => ({
        ...prev,
        type: current_store.user.section[0]?.id,
        company: current_store.user.companies[0]?.id,
        branch_dropdown:
          current_store.user.branch.length == 1
            ? current_store.user.branch[0]?.id
            : 0,
      }));
    }
  }, [current_store]);
  // console.log(current_store);
  useEffect(() => {
    if (current_store.user.token !== "") {
      setloading(true);
      get_invoice(
        {
          token: current_store.user.token,
          // section_filter: current_store.user.section.map((el) => el.id),
          filters: {
            section: table_filters.type,
            branch:
              table_filters.branch_dropdown ||
              current_store.user.branch.map((el) => el.id),
            company: table_filters.company,
          },
        },
        (data) => {
          // console.log(data);
          setloading(false);
          setto_show_table([...data.invoice_data]);
        }
      );
    }
  }, [
    current_store,
    table_filters.type,
    table_filters.branch_dropdown,
    table_filters.company,
  ]);
  // console.log(table_filters);

  return (
    <div className="account_page">
      <CompanyYearSelector
        table_filters={table_filters}
        settable_filters={settable_filters}
      />
      <AccountTableFilter
        to_show_table={to_show_table}
        sections={page_data.section}
        table_filters={table_filters}
        settable_filters={settable_filters}
        // current_store={current_store}
      />
      {loading ? (
        <div
          className="center"
          style={{
            margin: "50px 0 0 0",
          }}
        >
          Loading...
        </div>
      ) : (
        <Table
          cust_class={
            "invoice_table" +
            (!current_store.user.is_admin ? " employee_table" : "")
          }
          accounts_condition={true}
          table_header={[
            <div
              className="has_arrows"
              onClick={() => {
                settable_filters((prev) => ({
                  ...prev,
                  lastediteddby: "",
                  branch: "",
                  invoicenumber:
                    prev.invoicenumber === "ascending"
                      ? "decending"
                      : prev.invoicenumber === "decending"
                      ? ""
                      : "ascending",
                }));
              }}
            >
              Invoice Number
              <div className="arrow_group">
                <img
                  className={
                    table_filters.invoicenumber == "ascending" ? "active" : ""
                  }
                  src={arrow_up}
                />
                <img
                  className={
                    table_filters.invoicenumber == "decending" ? "active" : ""
                  }
                  src={arrow_down}
                />
              </div>
            </div>,
            "Invoice Date",

            current_store.user.is_admin ? (
              <div
                className="has_arrows"
                onClick={() => {
                  settable_filters((prev) => ({
                    ...prev,
                    invoicenumber: "",
                    lastediteddby: "",
                    branch: "",
                    lastediteddby:
                      prev.lastediteddby === "ascending"
                        ? "decending"
                        : prev.lastediteddby === "decending"
                        ? ""
                        : "ascending",
                  }));
                }}
              >
                Last Edited By
                <div className="arrow_group">
                  <img
                    className={
                      table_filters.lastediteddby == "ascending" ? "active" : ""
                    }
                    src={arrow_up}
                  />
                  <img
                    className={
                      table_filters.lastediteddby == "decending" ? "active" : ""
                    }
                    src={arrow_down}
                  />
                </div>
              </div>
            ) : (
              ""
            ),

            "Remarks",
            current_store.user.is_admin ? (
              <div
                className="has_arrows"
                onClick={() => {
                  settable_filters((prev) => ({
                    ...prev,
                    lastediteddby: "",
                    invoicenumber: "",
                    branch:
                      prev.branch === "ascending"
                        ? "decending"
                        : prev.branch === "decending"
                        ? ""
                        : "ascending",
                  }));
                }}
              >
                Branch
                <div className="arrow_group">
                  <img
                    className={
                      table_filters.branch == "ascending" ? "active" : ""
                    }
                    src={arrow_up}
                  />
                  <img
                    className={
                      table_filters.branch == "decending" ? "active" : ""
                    }
                    src={arrow_down}
                  />
                </div>
              </div>
            ) : (
              ""
            ),
            "Action",
          ].filter((el) => el != "")}
          table_body={[
            ...to_show_table
              .filter((el) => {
                if (table_filters.date) {
                  return (
                    moment(Number(el.date_time)).format("D/M/YYYY") ===
                    moment(table_filters.date).format("D/M/YYYY")
                  );
                }
                // return true;
                let current_date = new Date(Number(el.date_time));
                return (
                  (current_date.getFullYear() === table_filters.year &&
                    current_date.getMonth() >= 3) ||
                  (current_date.getFullYear() === table_filters.year + 1 &&
                    current_date.getMonth() < 3)
                );
              })
              .filter((el) => {
                if (table_filters.search) {
                  return (el.invoice_number + "")
                    .toLowerCase()
                    .match(table_filters.search);
                }
                return true;
              })
              .sort((a, b) => {
                if (table_filters.invoicenumber === "ascending") {
                  return a.invoice_number > b.invoice_number
                    ? 1
                    : b.invoice_number > a.invoice_number
                    ? -1
                    : 0;
                }
                if (table_filters.invoicenumber === "decending") {
                  return a.invoice_number < b.invoice_number
                    ? 1
                    : b.invoice_number < a.invoice_number
                    ? -1
                    : 0;
                }
                if (table_filters.lastediteddby === "ascending") {
                  return a.edited_by[0].user_data.name >
                    b.edited_by[0].user_data.name
                    ? 1
                    : b.edited_by[0].user_data.name >
                      a.edited_by[0].user_data.name
                    ? -1
                    : 0;
                }
                if (table_filters.lastediteddby === "decending") {
                  return a.edited_by[0].user_data.name <
                    b.edited_by[0].user_data.name
                    ? 1
                    : b.edited_by[0].user_data.name <
                      a.edited_by[0].user_data.name
                    ? -1
                    : 0;
                }
                if (table_filters.branch === "ascending") {
                  return a.branch.name > b.branch.name
                    ? 1
                    : b.branch.name > a.branch.name
                    ? -1
                    : 0;
                }
                if (table_filters.branch === "decending") {
                  return a.branch.name < b.branch.name
                    ? 1
                    : b.branch.name < a.branch.name
                    ? -1
                    : 0;
                }
                return true;
              })
              .map((el) =>
                [
                  el.is_deleted,
                  el.invoice_number,
                  // el.date_time,
                  // new Date(el.date_time).toString(),
                  moment(Number(el.date_time))
                    .format("D/M/YYYY")
                    .split("/")
                    .map((el) => (el < 10 ? `0${el}` : el))
                    .join("/"),
                  current_store.user.is_admin
                    ? el.edited_by.reverse()[0]?.user_data?.name
                    : "",
                  el.remark || <div className="center">-</div>,
                  current_store.user.is_admin ? el.branch.name : "",
                  <div className="invoice_actions">
                    {el.is_deleted === "temp" ? (
                      <>
                        <div
                          className="btn_normal"
                          // onClick={() => {
                          //   setcurrent_invoice({ ...el });
                          //   seteditinvoice_modal(true);
                          // }}
                          onClick={() => {
                            setcurrent_invoice({ ...el });
                            sethard_delete(true);
                          }}
                        >
                          Delete
                        </div>
                        {hard_delete && (
                          <DeleteInvoice
                            close={() => sethard_delete(false)}
                            hard_delete={true}
                            current_invoice={current_invoice}
                          />
                        )}
                      </>
                    ) : (
                      <div
                        className="btn_normal"
                        onClick={() => {
                          setcurrent_invoice({ ...el });
                          seteditinvoice_modal(true);
                        }}
                      >
                        Edit
                      </div>
                    )}
                    {/* <div>Archive {el?.is_deleted}</div> */}
                  </div>,
                ].filter((el) => el !== "")
              ),
          ]}
        />
      )}
      {editinvoice_modal && (
        <EditInvoice
          current_invoice={current_invoice}
          table_filters={table_filters}
          close={() => seteditinvoice_modal(false)}
        />
      )}
    </div>
  );
};
const AccountTableFilter = ({
  to_show_table,
  sections,
  table_filters,
  settable_filters,
  // current_store,
}) => {
  const current_store = useSelector((state) => state);
  const [addinvoice_modal, setaddinvoice_modal] = useState(false);
  return (
    <div className="accounttablefilter">
      <div className="table_main_filter">
        {sections.map(({ id, value }) => (
          <div
            className={"item" + (table_filters.type === id ? " active" : "")}
            onClick={() => {
              settable_filters((prev) => ({ ...prev, type: id }));
            }}
          >
            {value}
          </div>
        ))}
      </div>
      <div className="table_submain_filter">
        <div>
          {sections.filter((el) => el.id === table_filters.type)[0]?.value} for{" "}
          {table_filters.year}
        </div>
        <Search
          cust_placeholder="Search Invoice No."
          current_value={table_filters.search}
          setcurrent_value={(data) =>
            settable_filters((prev) => ({ ...prev, search: data }))
          }
        />
        <DatePicker
          current_value={table_filters.date}
          setcurrent_value={(data) =>
            settable_filters((prev) => ({ ...prev, date: data }))
          }
        />
        {current_store.user.branch.length > 1 && (
          <Dropdown
            option_arr={[
              { id: 0, value: "All Branches..." },
              ...current_store.user.branch,
            ]}
            current_value={table_filters.branch_dropdown}
            setcurrent_value={(data) => {
              settable_filters((prev) => ({ ...prev, branch_dropdown: data }));
            }}
          />
        )}
        <div className="btn_normal" onClick={() => setaddinvoice_modal(true)}>
          Add {sections.filter((el) => el.id === table_filters.type)[0]?.value}
        </div>
      </div>
      {addinvoice_modal && (
        <AddInvoice
          to_show_table={to_show_table}
          table_filters={table_filters}
          close={() => setaddinvoice_modal(false)}
        />
      )}
    </div>
  );
};
const AddInvoice = ({ to_show_table, table_filters, close }) => {
  const navigate = useNavigate();
  const current_store = useSelector((state) => state);
  const [invoice_data, setinvoice_data] = useState({
    invoice_number: "",
    branch_id: "",
    section_id: "",
    company_id: "",
    date: new Date(),
    // current_date: new Date(),
    remark: "",
    options: [],
  });
  const [invoice_err, setinvoice_err] = useState({
    invoice_number: "",
    branch_id: "",
    section_id: "",
    company_id: "",
    date: "",
    // current_date: "",
    remark: "",
    options: [],
  });
  // const [file, setFile] = useState(null);
  const [filedata, setfiledata] = useState([]);
  const [filename, setfilename] = useState([]);
  const [deleteid, setdeleteid] = useState("");
  const [save_btn, setsave_btn] = useState(false);
  const [saving_btn, setsaving_btn] = useState(false);

  const calc_size_base64 = (base64String) => {
    var applyPaddingsRules = true;

    // Remove MIME-type from the base64 if exists
    var length = base64String.split("base64,")[1].length;

    var fileSizeInByte = Math.ceil(length / 4) * 3;

    if (applyPaddingsRules && length >= 2) {
      var paddings = base64String.slice(0, -2);
      fileSizeInByte =
        paddings === "=="
          ? fileSizeInByte - 2
          : paddings[1] === "="
          ? fileSizeInByte - 1
          : fileSizeInByte;
    }
    return fileSizeInByte;
  };

  // pdf merge code do not delete
  // const [mergedPdfUrl, setMergedPdfUrl] = useState();
  // const [mergedPdfUrl_base64, setmergedPdfUrl_base64] = useState("");
  // useEffect(() => {
  //   if (filedata.length == 0) {
  //     return;
  //   }
  //   const render = async () => {
  //     const merger = new PDFMerger();

  //     for (const file of filedata) {
  //       await merger.add(file);
  //     }
  //     const mergedPdf = await merger.saveAsBlob();
  //     var reader = new FileReader();
  //     reader.readAsDataURL(mergedPdf);
  //     reader.onloadend = function () {
  //       setmergedPdfUrl_base64(reader.result);
  //     };
  //     const url = URL.createObjectURL(mergedPdf);
  //     return setMergedPdfUrl(url);
  //   };
  //   render().catch((err) => {
  //     throw err;
  //   });

  //   setMergedPdfUrl({});
  // }, [filedata, setMergedPdfUrl]);
  useEffect(() => {
    if (current_store.user.token !== "") {
      setinvoice_data((prev) => ({
        ...prev,
        options: [
          ...current_store.user.section
            .filter((el) => el.id === table_filters.type)[0]
            ?.options?.map((el) => ({
              id: el.id,
              value: el.value,
              selected_option: "pending",
            })),
        ],

        branch_id: current_store.user.branch[0].id,
      }));
    }
  }, [current_store]);

  useEffect(() => {
    if (
      invoice_data.invoice_number !== "" &&
      invoice_err.invoice_number == "" &&
      filedata.length > 0
    ) {
      setsave_btn(true);
    } else {
      setsave_btn(false);
    }
  }, [invoice_data, filedata]);

  const fileToBase64 = (file, cb) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      cb(null, reader.result);
    };
    reader.onerror = function (error) {
      cb(error, null);
    };
  };
  const onUploadFileChange = ({ target }) => {
    if (target.files < 1 || !target.validity.valid) {
      return;
    }
    fileToBase64(target.files[0], (err, result) => {
      if (result) {
        if (result.split(";")[0] == "data:application/pdf") {
          setfilename([...filename, target.files[0]]);
          setfiledata([...filedata, result]);
        }
      }
    });
  };
  const delete_this_file = (index, type) => {
    if (deleteid == "") {
      let filedata_copy = [...filedata];
      let filename_copy = [...filename];
      filedata_copy.splice(index, 1);
      filename_copy.splice(index, 1);
      setfiledata(filedata_copy);
      setfilename(filename_copy);
      setdeleteid("");
    }
  };
  const saveinvoice = () => {
    if (!save_btn) {
      return;
    }
    // console.log(table_filters);
    setsaving_btn(true);
    save_invoice(
      {
        invoice_number: invoice_data.invoice_number,
        branch_id: invoice_data.branch_id,
        section_id: table_filters.type,
        company_id: table_filters.company,
        date_time: invoice_data.date.valueOf(),
        remark: invoice_data.remark,
        options: invoice_data.options,
        file_arr: [...filedata.map((el) => ({ base64: el.split(",")[1] }))],
        path:
          current_store.user.section.filter(
            (el) => el.id === table_filters.type
          )[0]?.value +
          "/" +
          invoice_data.invoice_number,
        token: current_store.user.token,
      },
      (data) => {
        // setsaving_btn(tr)
        console.log(data);
        return window.location.reload();
      }
    );
  };
  // console.log(to_show_table);
  return (
    <div
      className="addinvoice"
      id="addinvoice"
      onClick={({ target }) => (target.id == "addinvoice" ? close() : "")}
    >
      <div>
        <div className="header">
          {
            current_store.user.companies.filter(
              (el) => el.id === table_filters.company
            )[0]?.value
          }{" "}
          ({table_filters.year}){"->"}
          {
            current_store.user.section.filter(
              (el) => el.id === table_filters.type
            )[0]?.value
          }
        </div>
        <div className="body">
          <div className="input_group">
            <div className="cust_input">
              <div className="title">Invoice number</div>
              <input
                type="text"
                value={invoice_data.invoice_number}
                onChange={({ target }) => {
                  console.log(
                    target.value,
                    to_show_table,
                    to_show_table.filter(
                      (el) => el.invoice_number == target.value
                    )
                  );
                  setinvoice_data((prev) => ({
                    ...prev,
                    invoice_number: target.value,
                  }));
                  if (target.value === "") {
                    return setinvoice_err((prev) => ({
                      ...prev,
                      invoice_number: "Please enter Invoice Number",
                    }));
                  }
                  if (target.value === "") {
                    return setinvoice_err((prev) => ({
                      ...prev,
                      invoice_number: "Invoice Number Required",
                    }));
                  } else {
                    if (
                      to_show_table.filter(
                        (el) => el.invoice_number === target.value
                      ).length > 0
                    ) {
                      return setinvoice_err((prev) => ({
                        ...prev,
                        invoice_number: "Invoice number already used",
                      }));
                    }
                    return setinvoice_err((prev) => ({
                      ...prev,
                      invoice_number: "",
                    }));
                  }
                }}
              />
              <div className="error">{invoice_err.invoice_number}</div>
            </div>
            <div className="cust_input">
              <div className="title">Select Branch</div>
              <Dropdown
                {...{
                  option_arr: [...current_store.user.branch],
                  current_value: invoice_data.branch_id,
                  setcurrent_value: (data) => {
                    setinvoice_data((prev) => ({ ...prev, branch_id: data }));
                  },
                }}
              />
            </div>
          </div>
          <DatePicker
            clear={true}
            current_value={invoice_data.date}
            setcurrent_value={(data) => {
              setinvoice_data((prev) => ({ ...prev, date: data }));
              console.log(data + "");
              setinvoice_err((prev) => ({
                ...prev,
                date: data + "",
              }));
            }}
          />
          <div className="cust_input">
            <div className="title">Remark</div>
            <textarea
              rows={5}
              type="text"
              value={invoice_data.remark}
              onChange={({ target }) =>
                setinvoice_data((prev) => ({ ...prev, remark: target.value }))
              }
            />
          </div>
          <div className="option_group">
            {invoice_data.options.map((el) => (
              <div className="option_item">
                <div className="title">{el.value}</div>
                <div className="option_case_group">
                  <div
                    className={el.selected_option == "pending" ? " active" : ""}
                    onClick={() => {
                      setinvoice_data((prev) => ({
                        ...prev,
                        options: [
                          ...prev.options.map((el1) => {
                            if (el1.id === el.id) {
                              return {
                                ...el1,
                                selected_option: "pending",
                              };
                            }
                            return el1;
                          }),
                        ],
                      }));
                    }}
                  >
                    Pending
                  </div>
                  <div
                    className={
                      el.selected_option == "uploaded" ? " active" : ""
                    }
                    onClick={() => {
                      setinvoice_data((prev) => ({
                        ...prev,
                        options: [
                          ...prev.options.map((el1) => {
                            if (el1.id === el.id) {
                              return {
                                ...el1,
                                selected_option: "uploaded",
                              };
                            }
                            return el1;
                          }),
                        ],
                      }));
                    }}
                  >
                    Uploaded
                  </div>
                  <div
                    className={el.selected_option == "n/a" ? " active" : ""}
                    onClick={() => {
                      setinvoice_data((prev) => ({
                        ...prev,
                        options: [
                          ...prev.options.map((el1) => {
                            if (el1.id === el.id) {
                              return {
                                ...el1,
                                selected_option: "n/a",
                              };
                            }
                            return el1;
                          }),
                        ],
                      }));
                    }}
                  >
                    N/A
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="cust_input cust_attachment">
            <div className="title">
              <label htmlFor="attachment" pointer="true">
                Attachment
                <img pointer="true" src={add} />
                <input
                  type="file"
                  id="attachment"
                  name="myfile"
                  onChange={(e) => onUploadFileChange(e)}
                ></input>
                <div>
                  Total File Size -{" "}
                  {humanFileSize(
                    filedata.reduce(
                      (prev, curr) => (prev += Number(calc_size_base64(curr))),
                      0
                    )
                  )}
                </div>
              </label>
            </div>
            {filename.map((el, i) => (
              <div className="filename" key={i}>
                <img src={attach} />
                {el.name} [{humanFileSize(calc_size_base64(filedata[i]))}]
                {deleteid === i ? (
                  <div className="action_btn ml" pointer="denied">
                    Deleting..
                  </div>
                ) : (
                  <div
                    className="action_btn ml"
                    pointer="true"
                    onClick={() => {
                      setdeleteid(i);
                      delete_this_file(i);
                    }}
                  >
                    Delete
                  </div>
                )}
              </div>
            ))}
            {/* {filename.length > 0 && (
              <iframe src={"data:application/pdf;base64," + filedata[0]} />
            )} */}
          </div>
        </div>
        <div className="footer">
          <div onClick={() => close()}>Cancel</div>
          {saving_btn ? (
            <div className={"btn_normal active"}>Saving...</div>
          ) : (
            <div
              className={"btn_normal" + (!save_btn ? " active" : "")}
              onClick={() => {
                saveinvoice();
              }}
            >
              Save
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
const compare_objarr_values = (obj1, obj2) => {
  // console.log(obj1, obj2);
  if (obj1.length == 0 || obj2.length == 0) {
    return true;
  }
  for (let index = 0; index < obj1.length; index++) {
    let { id, selected_option } = obj1[index];
    let item_from_obj2 = obj2.filter((el) => el.id === id)[0];
    if (item_from_obj2.selected_option !== selected_option) {
      return false;
    }
  }
  return true;
};
const EditInvoice = ({ current_invoice, table_filters, close }) => {
  console.log(current_invoice);
  const navigate = useNavigate();
  const current_store = useSelector((state) => state);
  const [invoice_data, setinvoice_data] = useState({
    invoice_number: "",
    branch_id: "",
    section_id: "",
    company_id: "",
    date: new Date(),
    // current_date: new Date(),
    remark: "",
    options: [],
  });
  const [invoice_err, setinvoice_err] = useState({
    invoice_number: "",
    branch_id: "",
    section_id: "",
    company_id: "",
    date: "",
    // current_date: "",
    remark: "",
    options: [],
  });
  // const [file, setFile] = useState(null);
  const [oldfiledata, setoldfiledata] = useState([]);
  const [filedata, setfiledata] = useState([]);
  const [filename, setfilename] = useState([]);
  // const [filesizesold, setfilesizesold] = useState([]);
  // const [filesizesnew, setfilesizesnew] = useState([]);
  const [deleteid, setdeleteid] = useState("");
  const [save_btn, setsave_btn] = useState(false);
  const [saving_btn, setsaving_btn] = useState(false);
  const [soft_delete, setsoft_delete] = useState(false);
  // useEffect(() => {
  // if (oldfiledata.length > 0) {
  //   [...oldfiledata].map((el, i) =>
  //     get_file_size(el, (data) => {
  //       console.log(data);
  //     })
  //   );
  // }
  // }, [oldfiledata]);

  const calc_size_base64 = (base64String) => {
    var applyPaddingsRules = true;

    // Remove MIME-type from the base64 if exists
    var length = base64String.split("base64,")[1].length;

    var fileSizeInByte = Math.ceil(length / 4) * 3;

    if (applyPaddingsRules && length >= 2) {
      var paddings = base64String.slice(0, -2);
      fileSizeInByte =
        paddings === "=="
          ? fileSizeInByte - 2
          : paddings[1] === "="
          ? fileSizeInByte - 1
          : fileSizeInByte;
    }
    return fileSizeInByte;
  };
  // console.log(filedata);
  useEffect(() => {
    if (current_store.user.token !== "") {
      console.log(
        current_store.user.section
          .filter((el) => el.id === table_filters.type)[0]
          ?.options?.map((el) => ({
            id: el.id,
            value: el.value,
            selected_option: current_invoice.options.filter(
              (el1) => el1.id === el.id
            )[0].selected_option,
          }))
      );
      setinvoice_data((prev) => ({
        ...prev,
        options: [
          ...current_store.user.section
            .filter((el) => el.id === table_filters.type)[0]
            ?.options?.map((el) => ({
              id: el.id,
              value: el.value,
              selected_option: current_invoice.options.filter(
                (el1) => el1.id === el.id
              )[0].selected_option,
            })),
        ],
        invoice_number: current_invoice.invoice_number,
        branch_id: current_invoice.branch._id,
        date: new Date(Number(current_invoice.date_time)),
        remark: current_invoice.remark,
      }));
      // setfiledata([current_invoice.files]);
      // setfilename([
      //   ...current_invoice.files.map((el, i) => ({
      //     name: `${current_invoice.invoice_number}_${i + 1}`,
      //   })),
      // ]);
      setoldfiledata([
        ...(current_invoice?.files
          ? current_invoice.files.filter((el) => el)
          : []),
      ]);
    }
  }, [current_store, current_invoice]);
  // console.log(oldfiledata);

  useEffect(() => {
    if (
      current_invoice.remark !== invoice_data.remark ||
      !compare_objarr_values(
        [...current_invoice.options].map(({ id, selected_option }) => ({
          id,
          selected_option,
        })),

        [...invoice_data.options].map(({ id, selected_option }) => ({
          id,
          selected_option,
        }))
      ) ||
      oldfiledata.length !== current_invoice.files.length ||
      filedata.length > 0
    ) {
      setsave_btn(true);
    } else {
      setsave_btn(false);
    }
  }, [invoice_data, filedata, oldfiledata]);

  const fileToBase64 = (file, cb) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      cb(null, reader.result);
    };
    reader.onerror = function (error) {
      cb(error, null);
    };
  };
  const onUploadFileChange = ({ target }) => {
    if (target.files < 1 || !target.validity.valid) {
      return;
    }
    fileToBase64(target.files[0], (err, result) => {
      if (result) {
        if (result.split(";")[0] == "data:application/pdf") {
          setfilename([...filename, target.files[0]]);
          setfiledata([...filedata, result]);
        }
      }
    });
  };
  const delete_this_file = (index, type) => {
    if (type == 1) {
      if (deleteid == "") {
        let filedata_copy = [...filedata];
        let filename_copy = [...filename];
        filedata_copy.splice(index, 1);
        filename_copy.splice(index, 1);
        setfiledata(filedata_copy);
        setfilename(filename_copy);
        setdeleteid("");
      }
    } else {
      if (deleteid == "") {
        let oldfiledata_copy = [...oldfiledata];
        oldfiledata_copy.splice(index, 1);
        setoldfiledata(oldfiledata_copy);
        setdeleteid("");
      }
    }
  };
  const saveinvoice = () => {
    if (!save_btn) {
      return;
    }
    console.log(table_filters);
    setsaving_btn(true);
    update_invoice(
      {
        invoice_number: invoice_data.invoice_number,
        remark: invoice_data.remark,
        options: invoice_data.options,
        old_file_arr: [...oldfiledata],
        file_arr: [...filedata.map((el) => ({ base64: el.split(",")[1] }))],
        path:
          current_store.user.section.filter(
            (el) => el.id === table_filters.type
          )[0]?.value +
          "/" +
          invoice_data.invoice_number,
        token: current_store.user.token,
      },
      (data) => {
        // setsaving_btn(false);
        return window.location.reload();
      }
    );
  };
  return (
    <div
      className="editinvoice"
      id="editinvoice"
      onClick={({ target }) => (target.id == "editinvoice" ? close() : "")}
    >
      <div>
        <div className="header">
          {
            current_store.user.companies.filter(
              (el) => el.id === table_filters.company
            )[0]?.value
          }{" "}
          ({table_filters.year}){"->"}
          {
            current_store.user.section.filter(
              (el) => el.id === table_filters.type
            )[0]?.value
          }
        </div>
        <div className="body">
          <div className="input_group">
            <div className="cust_input">
              <div className="title">Invoice number</div>
              <input
                readOnly
                type="text"
                value={invoice_data.invoice_number}
                onChange={({ target }) => {
                  setinvoice_data((prev) => ({
                    ...prev,
                    invoice_number: target.value,
                  }));
                  if (target.value === "") {
                    return setinvoice_err((prev) => ({
                      ...prev,
                      invoice_number: "Please enter Invoice Number",
                    }));
                  }
                  if (target.value === "") {
                    return setinvoice_err((prev) => ({
                      ...prev,
                      invoice_number: "Invoice Number Required",
                    }));
                  } else {
                    return setinvoice_err((prev) => ({
                      ...prev,
                      invoice_number: "",
                    }));
                  }
                }}
              />
              <div className="error">{invoice_err.invoice_number}</div>
            </div>
            <div className="cust_input">
              <div className="title">Select Branch</div>
              <Dropdown
                readonly={true}
                {...{
                  option_arr: [...current_store.user.branch],
                  current_value: invoice_data.branch_id,
                  setcurrent_value: (data) => {
                    // setinvoice_data((prev) => ({ ...prev, branch_id: data }));
                  },
                }}
              />
            </div>
          </div>
          <DatePicker
            readonly={true}
            clear={true}
            current_value={invoice_data.date}
            setcurrent_value={(data) => {
              setinvoice_data((prev) => ({ ...prev, date: data }));
              setinvoice_err((prev) => ({
                ...prev,
                date: data + "",
              }));
            }}
          />
          <div className="cust_input">
            <div className="title">Remark</div>
            <textarea
              rows={5}
              type="text"
              value={invoice_data.remark}
              onChange={({ target }) =>
                setinvoice_data((prev) => ({ ...prev, remark: target.value }))
              }
            />
          </div>
          <div className="option_group">
            {invoice_data.options.map((el) => (
              <div className="option_item">
                <div className="title">{el.value}</div>
                <div className="option_case_group">
                  <div
                    className={el.selected_option == "pending" ? " active" : ""}
                    onClick={() => {
                      setinvoice_data((prev) => ({
                        ...prev,
                        options: [
                          ...prev.options.map((el1) => {
                            if (el1.id === el.id) {
                              return {
                                ...el1,
                                selected_option: "pending",
                              };
                            }
                            return el1;
                          }),
                        ],
                      }));
                    }}
                  >
                    Pending
                  </div>
                  <div
                    className={
                      el.selected_option == "uploaded" ? " active" : ""
                    }
                    onClick={() => {
                      setinvoice_data((prev) => ({
                        ...prev,
                        options: [
                          ...prev.options.map((el1) => {
                            if (el1.id === el.id) {
                              return {
                                ...el1,
                                selected_option: "uploaded",
                              };
                            }
                            return el1;
                          }),
                        ],
                      }));
                    }}
                  >
                    Uploaded
                  </div>
                  <div
                    className={el.selected_option == "n/a" ? " active" : ""}
                    onClick={() => {
                      setinvoice_data((prev) => ({
                        ...prev,
                        options: [
                          ...prev.options.map((el1) => {
                            if (el1.id === el.id) {
                              return {
                                ...el1,
                                selected_option: "n/a",
                              };
                            }
                            return el1;
                          }),
                        ],
                      }));
                    }}
                  >
                    N/A
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="cust_input cust_attachment">
            <div className="title">
              <label htmlFor="attachment" pointer="true">
                Attachment
                <img pointer="true" src={add} />
                <input
                  type="file"
                  id="attachment"
                  name="myfile"
                  onChange={(e) => onUploadFileChange(e)}
                ></input>
                <div>
                  Total File Size - (
                  {humanFileSize(
                    oldfiledata.reduce((prev, curr) => (prev += curr.size), 0)
                  )}{" "}
                  {filedata.length > 0 && (
                    <>
                      +{" "}
                      {humanFileSize(
                        filedata.reduce(
                          (prev, curr) =>
                            (prev += Number(calc_size_base64(curr))),
                          0
                        )
                      )}{" "}
                      ={" "}
                      {humanFileSize(
                        oldfiledata.reduce(
                          (prev, curr) => (prev += curr.size),
                          0
                        ) +
                          filedata.reduce(
                            (prev, curr) =>
                              (prev += Number(calc_size_base64(curr))),
                            0
                          )
                      )}
                    </>
                  )}
                  )
                </div>
              </label>
            </div>
            {oldfiledata.map((el, i) => (
              <div className="filename" key={i}>
                <img src={attach} />
                {current_invoice.invoice_number}_({i + 1}).pdf [
                {humanFileSize(el.size)}]
                {deleteid === "oldfiledata" + i ? (
                  <div className="action_btn ml" pointer="denied">
                    Deleting..
                  </div>
                ) : (
                  <div
                    className="action_btn ml"
                    pointer="true"
                    onClick={() => {
                      setdeleteid(i);
                      delete_this_file("oldfiledata" + i, false);
                    }}
                  >
                    Delete
                  </div>
                )}
                <div
                  className="action_btn"
                  onClick={() => window.open(el.link)}
                >
                  View
                </div>
              </div>
            ))}
            {filename.map((el, i) => (
              <div className="filename" key={i}>
                <img src={attach} />
                {el.name} [{humanFileSize(calc_size_base64(filedata[i]))}]
                {deleteid === i ? (
                  <div className="action_btn ml" pointer="denied">
                    Deleting..
                  </div>
                ) : (
                  <div
                    className="action_btn ml"
                    pointer="true"
                    onClick={() => {
                      setdeleteid(i);
                      delete_this_file(i, true);
                    }}
                  >
                    Delete
                  </div>
                )}
              </div>
            ))}
            {/* {filename.length > 0 && (
              <iframe src={"data:application/pdf;base64," + filedata[0]} />
            )} */}
          </div>
        </div>
        <div className="footer">
          <div onClick={() => close()}>Cancel</div>
          <div className="editinvoice_btngroup">
            <div
              className={"btn_normal delete_btn"}
              onClick={() => setsoft_delete(true)}
            >
              Delete
            </div>
            {soft_delete && (
              <DeleteInvoice
                close={() => {
                  console.log(1);
                  setsoft_delete(false);
                }}
                current_invoice={current_invoice}
              />
            )}
            {saving_btn ? (
              <div className={"btn_normal active"}>Saving...</div>
            ) : (
              <div
                className={"btn_normal" + (!save_btn ? " active" : "")}
                onClick={() => {
                  saveinvoice();
                }}
              >
                Save
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const DeleteInvoice = ({ close, current_invoice, hard_delete }) => {
  const [deleting, setdeleting] = useState(false);
  const current_store = useSelector((state) => state);
  return (
    <div
      className="delete_modal"
      id="delete_modal"
      onClick={({ target }) => (target.id === "delete_modal" ? close() : "")}
    >
      <div className="f-sen">
        <div className="txt c-red">Are you sure you want to delete this ?</div>
        <div class="txt2">This action cannot be undone!</div>
        <div className="btn_group">
          <div
            className="f-rel"
            style={{
              fontSize: 18,
              lineHeight: 1,
              color: "#000",
            }}
            onClick={() => close()}
          >
            Cancel
          </div>
          <div
            className={"btn_normal" + (deleting ? " active" : "")}
            onClick={() => {
              setdeleting(true);
              if (hard_delete) {
                delete_item({
                  id: current_invoice._id,
                  token: current_store.user.token,
                  type: "invoice_perma",
                });
              } else {
                delete_item({
                  id: current_invoice._id,
                  token: current_store.user.token,
                  type: "invoice",
                });
              }
            }}
          >
            {deleting ? "Deleting..." : "Delete"}
          </div>
        </div>
      </div>
    </div>
  );
};
