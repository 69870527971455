import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { add_branch, add_employee } from "../../Api/api";
import { Dropdown } from "../../Component/Custom/Dropdown/Dropdown";

export const Addbranch = ({
  section,
  RolesDropdown,
  BranchesDropdown,
  close,
}) => {
  const current_store = useSelector((state) => state);

  const [page_data, setpage_data] = useState({
    name: "",
    email: "",
    mobile: "",
    password: "",
    address: "",
    type: 0,
    branch: 0,
    section: [],
  });
  const [page_err, setpage_err] = useState({
    name_err: "",
    mobile_err: "",
    email_err: "",
    password_err: "",
    usertype_err: "",
    branch_err: "",
  });
  const [save_btn, setsave_btn] = useState(false);
  const handel_save = () => {
    if (save_btn) {
      add_branch(
        {
          name: page_data.name,
          address: page_data.address,
          token: current_store.user.token,
        },
        (data) => {
          console.log(data);
        }
      );
    }
  };
  console.log(page_data);
  useEffect(() => {
    let { name, address } = page_data;
    if (name !== "") {
      return setsave_btn(true);
    } else {
      return setsave_btn(false);
    }
  }, [page_data, page_err]);

  //   console.log(page_data);
  return (
    <div
      className="adduser f-rale"
      id="adduser"
      onClick={({ target }) => {
        if (target.id == "adduser") {
          close();
        }
      }}
    >
      <div>
        <div className="header c-red f-sen">Add Branch</div>
        <div className="body">
          <div className="input_group">
            <div className="input" style={{ width: "100%" }}>
              <div className="input_txt">Name</div>
              <input
                type="text"
                value={page_data.name}
                onChange={({ target }) => {
                  setpage_data((prev) => ({ ...prev, name: target.value }));
                  if (target.value == "") {
                    setpage_err((prev) => ({
                      ...prev,
                      name_err: "Please enter branch name",
                    }));
                  } else {
                    setpage_err((prev) => ({
                      ...prev,
                      name_err: "",
                    }));
                  }
                }}
              />
              <div className="error">{page_err.name_err}</div>
            </div>
          </div>
          <div>
            <div className="input">
              <div className="input_txt">Address</div>
              <textarea
                rows="5"
                value={page_data.address}
                onChange={({ target }) => {
                  setpage_data((prev) => ({ ...prev, address: target.value }));
                }}
              />
            </div>
          </div>
        </div>
        <div className="footer">
          <div onClick={() => close()}>Cancel</div>
          <div
            className={"btn_normal" + (save_btn ? "" : " active")}
            onClick={() => handel_save()}
          >
            Save
          </div>
        </div>
      </div>
    </div>
  );
};
const SectionSelect = ({ options_arr, current_value }) => {
  return (
    <div className="section_select">
      <div>Select Section</div>
      <div className="section_select_group">
        {options_arr.map((el, i) => (
          <div
            key={i}
            className="selection_item"
            onClick={() => el.select_this(el.selection)}
          >
            <input
              type="checkbox"
              checked={el.is_selected(el.selection)}
              readOnly
            />
            <div>{el.name}</div>
          </div>
        ))}
      </div>
      <div className="error">
        {Object.values(current_value).includes(true)
          ? ""
          : "Select atleast one"}
      </div>
    </div>
  );
};
