const default_user = {
  name: "",
  is_admin: false,
  section: [],
  branch: [],
  token: "",
  companies: [],
};
export const user = {
  ...default_user,
};

const User = (state = user, { type, payload }) => {
  switch (type) {
    case "login":
      return {
        ...state,
        name: payload.name,
        is_admin: payload.is_admin,
        section: payload.section,
        branch: payload.branch,
        token: payload.token,
        companies: payload.companies,
      };
    case "logout":
      // localStorage.setItem("mtpl_data", "");
      return { ...state, ...default_user };
    default:
      return state;
  }
};
export default User;
