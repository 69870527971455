import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import logo from "../img/logo.png";

export const Navbar = ({ name }) => {
  const [current_active, setcurrent_active] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const current_store = useSelector((state) => state);
  useEffect(() => {
    setcurrent_active(window.location.pathname);
  }, [window.location.pathname]);
  console.log(current_active);
  if (!["/account", "/settings"].includes(current_active)) {
    return "";
  }
  return (
    <div className="navbar">
      {/* <div> */}
      <img src={logo} height="66" />
      {/* </div> */}
      <div className="welcome">
        Welcome {current_store.user.name.split(" ")[0] || "{name}"}, Have a nice
        day!
      </div>
      <div className="nav_actions">
        <div
          className={"btn" + (current_active === "/account" ? " active" : "")}
          onClick={() => navigate("/account")}
        >
          Account
        </div>
        {current_store.user.is_admin && (
          <div
            className={
              "btn" + (current_active === "/settings" ? " active" : "")
            }
            onClick={() => navigate("/settings")}
          >
            Settings
          </div>
        )}
        <div
          className="logout"
          onClick={() => {
            dispatch({ type: "logout" });
            navigate("/");
            localStorage.setItem("mtpl_data", "");
          }}
        >
          Logout
        </div>
      </div>
    </div>
  );
};
