import React from "react";
import search from "../../../img/icons/search.png";

export const Search = ({
  current_value,
  setcurrent_value,
  cust_placeholder,
}) => {
  return (
    <div className="cust_search">
      <img src={search} />
      <input
        type="text"
        value={current_value}
        placeholder={cust_placeholder || ""}
        onChange={({ target }) => {
          setcurrent_value(target.value);
        }}
      />
    </div>
  );
};
